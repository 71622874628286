@import '../../media.scss';

html {
  // --popup-close-icon: url("/public/images/reservation/close.png");
  --popup-close-hover-background-color: #f5f5f5;
  --popup-content-background-color: #f4f5f5;
}

.popup {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;

  .mask {
    width: inherit;
    height: inherit;
    position: fixed;
    background-color: #000;
    opacity: 0.72;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .popupContent {
    position: relative;
    border-radius: 0.25rem;
    border-radius: 1.1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--popup-content-background-color);
    z-index: 20;
    min-width: 25rem;
    min-height: 25rem;

    .closeBtn {
      width: 2.125rem;
      height: 2.125rem;
      background-color: inherit;
      background-color: transparent;
      // background-image: var(--popup-close-icon);
      background-image: url('../../../public/images/reservation/close.png');
      background-position: center;
      background-size: 1rem 1rem;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: absolute;
      top: 1.1875rem;
      top: -3.25rem;
      right: 1.1875rem;
      right: 0rem;
      cursor: pointer;
      z-index: 100;
    }

    .closeBtn:hover {
      background-color: var(--popup-close-hover-background-color);
      background-color: transparent;
    }
  }
}

.enter {
  .mask {
    animation: maskFadeIn 0.2s;
  }

  .popupContent {
    animation: fadeIn 0.2s;
  }
}

.leave {
  .mask {
    animation: maskFadeOut 0.2s;
    opacity: 0;
  }

  .popupContent {
    animation: fadeOut 0.2s;
    transform: scale(0);
  }
}

@keyframes fadeIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes maskFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}

@keyframes maskFadeOut {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

@include media-mobile {
  .popup {
    .popupContent {
      min-width: 18.75rem;
      min-width: 16.75rem;
      min-height: 18.75rem;
      min-height: 16.75rem;
      .closeBtn {
        width: 0.6875rem;
        width: 1.6875rem;
        height: 0.6875rem;
        height: 1.6875rem;
        top: 1.3125rem;
        top: -2.25rem;
        right: 0.875rem;
        right: 0rem;
      }
    }
  }
}

@include media-ipad {
  .popup {
    .popupContent {
      .titleArea {
        padding: 1.5rem 1.5625rem;
      }
    }
  }
}
