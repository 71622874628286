@import '../../media.scss';

.about {
  height: 100vh;
  background-image: url('../../../public/images/about/bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #ffffff;

  .aboutMain {
    width: 70%;
    margin: 0 auto;

    .aboutMainHeader {
      width: 100%;
      height: 140px;
      padding: 40px 0 40px;
      line-height: 60px;
      display: flex;
      justify-content: space-between;

      .buttonHome {
        cursor: pointer;
        transition: color 0.3s ease;

        &:hover {
          color: #1890ff;
        }
      }

      .buttonAbout {
        margin-left: 60px;
        cursor: pointer;
        transition: color 0.3s ease;

        &:hover {
          color: #1890ff;
        }
      }
    }

    .aboutMainContent {
      height: calc(100vh - 150px);
      display: flex;
      align-items: center;
      gap: 50px;

      .aboutMainContentLeft {
        flex: 1 1 55%;

        .aboutMainContentLeftTitle {
          margin-bottom: 50px;

          font-size: 42px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #e1f4ff;
          line-height: 74px;
          -webkit-text-stroke: 1px #badaf4;
          // text-stroke: 1px #badaf4;

          background: linear-gradient(
            0deg,
            #c6e7fe 0%,
            #ffffff 0%,
            #c6e7fe 100%
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .aboutMainContentLeftContent {
          width: 90%;

          ul li {
            /* 先去除小圆点 */
            list-style-type: none;
            margin-left: -40px;
            margin-bottom: 20px;
            padding-left: 24px;
            line-height: 30px;
            /* 设置字体向右移动20px，否则会遮挡到背景图片 */
            background: url('../../../public/images/home/triangles.png');
            /* 修改为背景图片 */
            background-size: 15px;
            background-repeat: no-repeat;
            background-position-y: 9px;
          }
        }
      }

      .aboutMainContentRight {
        flex: 1 1 40%;
        display: flex;
        justify-content: space-around;
        gap: 20px;
        row-gap: 20px;
        text-align: center;

        .imgLogo,
        .imgQR {
          margin-bottom: 24px;
        }
      }
    }
  }
}

@include media-mobile {
  .about {
    .aboutMain {
      width: 80%;

      .aboutMainHeader {
        padding: 30px 0;

        .buttonAbout {
          margin-left: 8px;
        }
      }

      .aboutMainContent {
        flex-wrap: wrap;
        gap: 20px;
        .aboutMainContentLeft {
          .aboutMainContentLeftTitle {
            margin-bottom: 18px;
            font-size: 45px;
            line-height: 60px;
          }
          .aboutMainContentLeftContent {
            width: 100%;
          }
        }

        .aboutMainContentRight {
          // flex-wrap: wrap;
          margin-bottom: 50px;

          .imgLogo,
          .imgQR {
            width: 120px;
          }
        }
      }
    }
  }
}

@include media-ipad {
  .about {
    .aboutMain {
      width: 80%;

      .aboutMainHeader {
        padding: 30px 0;

        .buttonAbout {
          margin-left: 16px;
        }
      }

      .aboutMainContent {
        flex-wrap: wrap;
        .aboutMainContentLeft {
          .aboutMainContentLeftTitle {
            margin-bottom: 18px;
            font-size: 45px;
            line-height: 60px;
          }
          .aboutMainContentLeftContent {
            width: 100%;
          }
        }

        .aboutMainContentRight {
          flex-wrap: wrap;
          margin-bottom: 50px;

          .imgLogo,
          .imgQR {
            width: 120px;
          }
        }
      }
    }
  }
}
