@import '../../media.scss';

.home {
  .pageA {
    background-image: url('../../../public/images/home/bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .wrap {
      width: 70%;
      margin: 0 auto;

      .header {
        width: 100%;
        height: 140px;
        padding: 40px 0 40px;
        line-height: 60px;
        color: #fff;
        display: flex;
        justify-content: space-between;

        .buttonHome {
          cursor: pointer;
          transition: color 0.3s ease;

          &:hover {
            color: #1890ff;
          }
        }

        .buttonAbout {
          margin-left: 60px;
          cursor: pointer;
          transition: color 0.3s ease;

          &:hover {
            color: #1890ff;
          }
        }
      }

      .main {
        padding: 0 0 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 40px;

        .content {
          padding: 50px 0;
          width: 50%;

          .title {
            // margin-top: 100px;
            font-size: 58px;
            font-size: 48px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #e1f4ff;
            line-height: 74px;
            -webkit-text-stroke: 1px #badaf4;
            // text-stroke: 1px #badaf4;

            background: linear-gradient(
              0deg,
              #c6e7fe 0%,
              #ffffff 0%,
              #c6e7fe 100%
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .desc {
            margin: 60px 0 52px;
            line-height: 30px;
            color: #fff;
          }

          .action {
            .buttonVisitNow {
              width: 200px;
              height: 60px;
              background: linear-gradient(-90deg, #07e0ff, #00ffdc, #0dbce5);
              border-radius: 30px;
              border: unset;

              font-size: 22px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000b49;

              cursor: pointer;
              transition: opacity 0.3s ease;

              &:hover {
                opacity: 0.8;
              }
            }

            .buttonApplyForDemo {
              width: 200px;
              height: 60px;
              border: 1px solid #00ffdc;
              /* border-image: linear-gradient(-90deg, #00FFDC, #0DBEE5) 1 1; */
              border-radius: 30px;
              margin-left: 20px;
              background: transparent;
              color: #fff;

              cursor: pointer;
              transition: opacity 0.3s ease;

              &:hover {
                opacity: 0.8;
              }

              span {
                font-size: 22px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000b49;

                background: linear-gradient(
                  -90deg,
                  #07e0ff 0%,
                  #00ffdc 0%,
                  #0dbce5 100%
                );
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
            }
          }
        }

        .image {
          width: 50%;
          text-align: center;

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }

  .pageB {
    background: #f9fdff;

    .wrap {
      width: 70%;
      margin: 0 auto;
      padding: 120px 0 60px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      gap: 40px;

      .image {
        width: 50%;
        text-align: center;

        img {
          width: 100%;
          height: auto;
        }
      }

      .content {
        width: 50%;

        .title {
          margin: 0 0 40px;
          font-size: 50px;
          font-size: 36px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          line-height: 74px;
        }

        .desc {
          margin-bottom: 14px;
          font-size: 16px;
          font-weight: 600;
          color: #333333;
          line-height: 30px;
        }

        .text {
          margin-bottom: 14px;
        }
      }
    }
  }

  .pageC {
    background: #ffffff;
  }

  .pageFooter {
    background-image: url('../../../public/images/home/bg_ai.png');
    background-repeat: no-repeat;
    /* background-size: 100% 872px; */
    background-position: center;
    background-size: cover;

    .wrap {
      width: 70%;
      margin: 0 auto;
      padding: 120px 0 60px;

      .content {
        width: 40%;

        .title {
          margin: 0 0 40px;
          font-size: 50px;
          font-size: 36px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          line-height: 74px;
        }

        .text {
          margin-bottom: 14px;
        }

        .image {
          margin-left: -10px;

          img {
            width: 100%;
          }
        }
      }

      .horizontalDivider {
        width: 100%;
        height: 1px;
        margin: 30px 0;
        background: #000000;
        opacity: 0.09;
      }

      .copyright {
        display: flex;
        justify-content: space-around;
        gap: 40px;
        justify-content: flex-start;
        align-items: center;

        .logoImg img {
          width: 110px;
        }

        .text {
          color: #73767c;
        }

        .ICPNumber {
          color: #73767c;

          &:hover,
          &:active {
            color: #1890ff;
          }
        }
      }
    }
  }

  ul li {
    /* 先去除小圆点 */
    list-style-type: none;
    margin-left: -40px;
    margin-bottom: 14px;
    padding-left: 24px;
    font-size: 14px;
    line-height: 30px;
    /* 设置字体向右移动20px，否则会遮挡到背景图片 */
    background: url('../../../public/images/home/triangles.png');
    /* 修改为背景图片 */
    background-size: 15px;
    background-repeat: no-repeat;
    background-position-y: 9px;
  }
}

.modal {
  width: 516px;
  height: 550px;

  .modalTitle {
    width: 100%;
  }

  .modalContent {
    width: 100%;
    padding: 20px 40px;

    :global(.ant-input-affix-wrapper) {
      padding: 8px 11px;
      border-radius: 8px;
    }

    .prefixCustom {
      margin-right: 12px;
      color: #333333;
    }

    .buttonForm {
      cursor: pointer;
      transition: opacity 0.3s ease;

      img {
        width: 100%;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

@include media-mobile {
  .home {
    .pageA {
      .wrap {
        width: 80%;

        .header {
          padding: 30px 0;

          .buttonAbout {
            margin-left: 8px;
          }
        }

        .main {
          flex-wrap: wrap-reverse;
          gap: 0px;

          .content {
            padding: 10px 0;
            width: 100%;
            text-align: center;

            .title {
              margin-top: 0px;
              font-size: 34px;
              line-height: 50px;
            }

            .desc {
              margin: 21px 0 21px;
              line-height: 21px;
              text-align: left;
            }

            .action {
              display: inline-flex;
              gap: 20px;
              // margin-left: -10px;

              .buttonVisitNow {
                width: 140px;
                height: 40px;

                font-size: 18px;
              }

              .buttonApplyForDemo {
                width: 140px;
                height: 40px;
                margin-left: 0px;

                span {
                  font-size: 18px;
                }
              }
            }
          }

          .image {
            width: 100%;
          }
        }
      }
    }

    .pageB {
      .wrap {
        width: 80%;
        margin: 0 auto;
        padding: 60px 0 30px;
        padding: 40px 0 20px;
        gap: 20px;
        flex-wrap: wrap;

        .image {
          width: 100%;
        }

        .content {
          width: 100%;

          .title {
            margin: 0 0 24px;
            font-size: 32px;
            font-size: 24px;
            line-height: 1.5;
            text-align: center;
          }
        }
      }
    }

    .pageC {
      .wrap {
        flex-wrap: wrap-reverse;
      }
    }

    .pageFooter {
      background-image: unset;
      .wrap {
        width: 80%;
        margin: 0 auto;
        padding: 60px 0 30px;

        .content {
          width: 100%;

          .title {
            margin: 0 0 24px;
            font-size: 32px;
            font-size: 24px;
            line-height: 1.5;
            text-align: center;
          }

          .image {
            margin-left: 0px;
          }
        }

        .copyright {
          display: flex;
          justify-content: space-around;
          gap: 40px;
          justify-content: flex-start;
          align-items: center;

          .logoImg img {
            width: 110px;
          }

          .text {
            color: #73767c;
          }
        }
      }
    }

    ul li {
      margin-bottom: 14px;
      line-height: 21px;
      background-position-y: 6px;
    }
  }

  .modal {
    width: 340px;
    height: 460px;

    .modalTitle {
      width: 100%;
    }

    .modalContent {
      width: 100%;
      padding: 20px 20px;

      :global(.ant-input-affix-wrapper) {
        padding: 6.5px 11px;
      }

      .prefixCustom {
        margin-right: 12px;
      }
    }
  }
}

@include media-ipad {
  .home {
    .pageA {
      .wrap {
        width: 80%;

        .header {
          padding: 30px 0;

          .buttonAbout {
            margin-left: 16px;
          }
        }

        .main {
          flex-wrap: wrap-reverse;
          gap: 0px;

          .content {
            padding: 10px 0;
            width: 100%;
            text-align: center;

            .title {
              margin-top: 0px;
              font-size: 36px;
              line-height: 50px;
            }

            .desc {
              margin: 21px 0 21px;
              line-height: 21px;
            }

            .action {
              display: inline-flex;
              gap: 20px;
              // margin-left: -10px;

              .buttonVisitNow {
                width: 140px;
                height: 40px;

                font-size: 18px;
              }

              .buttonApplyForDemo {
                width: 140px;
                height: 40px;
                margin-left: 0px;

                span {
                  font-size: 18px;
                }
              }
            }
          }

          .image {
            width: 100%;
          }
        }
      }
    }

    .pageB {
      .wrap {
        width: 80%;
        margin: 0 auto;
        padding: 60px 0 30px;
        gap: 20px;
        flex-wrap: wrap;

        .image {
          width: 100%;
        }

        .content {
          width: 100%;

          .title {
            margin: 0 0 40px;
            font-size: 32px;
            line-height: 42px;
          }
        }
      }
    }

    .pageC {
      .wrap {
        flex-wrap: wrap-reverse;
      }
    }

    .pageFooter {
      .wrap {
        width: 80%;
        margin: 0 auto;
        padding: 60px 0 30px;

        .content {
          width: 100%;

          .title {
            margin: 0 0 40px;
            font-size: 32px;
            line-height: 42px;
          }

          .image {
            margin-left: 0px;
          }
        }

        .copyright {
          display: flex;
          justify-content: space-around;
          gap: 40px;
          justify-content: flex-start;
          align-items: center;

          .logoImg img {
            width: 110px;
          }

          .text {
            color: #73767c;
          }
        }
      }
    }

    ul li {
      margin-bottom: 14px;
      line-height: 21px;
      background-position-y: 6px;
    }
  }
}
